import React, { useState, useEffect } from "react";
import Head from "next/head";
import CmsRow from "./CmsRow";
import CmsFooter from "./CmsFooter";

import dynamic from "next/dynamic";

import Aos from "aos";
import "aos/dist/aos.css";
import { useRouter } from "next/router";

function CmsPage({ structure }) {
  

  useEffect(() => {
    if (document.readyState !== "loading") {
      /* HOMEPAGE */
      applyAosToElement(".homepage #riga1 .checkContainer", "fade-left");
      applyAosToElement(".homepage #riga1 .joinContainer", "fade-left");
      applyAosToElement(".homepage #riga2 .player-wrapper", "fade-right");
      applyAosToElement(".homepage #riga2 .text", "fade-left");
      applyAosToElement(".homepage #riga3 .text", "fade-up");
      applyAosToElement(".homepage #riga3 #swiper-1656668853", "fade-up");
      applyAosToElement(".homepage #riga4 .text", "fade-right");
      applyAosToElement(".homepage #riga4 .player-wrapper", "fade-left");
      applyAosToElement(".homepage #riga5 .player-wrapper", "fade-right");
      applyAosToElement(".homepage #riga5 .text", "fade-left");
      applyAosToElement(".homepage #map-title .text", "fade-up");
      applyAosToElement(".homepage .mapOverlayBox", "fade-right");

      //   applyAosToElement('.home #riga1 .swiper-slide .img-container', 'fade-left');
      //   applyAosToElement('.home #riga2 .text', 'fade-up');
      //   applyAosToElement('.home #riga4 .text', 'fade-right');
      //   applyAosToElement('.home #riga4 .image', 'fade-left');
      //   applyAosToElement('.home #riga5 .text', 'fade-left');
      //   applyAosToElement('.home #riga5 .image', 'fade-right');
      //   applyAosToElement('.home #riga6 h2', 'fade-up');
      //   applyAosToElement('.home #diconodinoi', 'fade-up');
      //   applyAosToElement('#swiper-1654608810', 'fade-up');
      //   applyAosToElement('.home #riga8', 'fade-up');
      //   applyAosToElementByIndex('.home #riga9 .text', 0, 'fade-right');
      //   applyAosToElementByIndex('.home #riga9 .text', 1, 'fade-up');
      //   applyAosToElementByIndex('.home #riga9 .text', 2, 'fade-left');
      //   applyAosToElementByIndex('.home #riga10 .text', 0, 'fade-up');
      //   applyAosToElementByIndex('.home #riga10 .text p', 0, 'fade-up');

      Aos.init();
    }
  }, []);

  const iubendaImplementation = `
  <script type="text/javascript">
  var _iub = _iub || [];
  _iub.csConfiguration = {"askConsentAtCookiePolicyUpdate":true,"cookiePolicyInOtherWindow":true,"countryDetection":true,"floatingPreferencesButtonDisplay":"bottom-right","gdprAppliesGlobally":false,"lang":"en","perPurposeConsent":true,"preferenceCookie":{"expireAfter":180},"siteId":2779444,"whitelabel":false,"cookiePolicyId":32370540, "banner":{ "acceptButtonColor":"#15A1B1","acceptButtonDisplay":true,"backgroundColor":"#3A3A3C","closeButtonRejects":true,"customizeButtonDisplay":true,"explicitWithdrawal":true,"fontSizeCloseButton":"26px","listPurposes":true,"ownerName":"www.tarponville.com","position":"bottom","rejectButtonColor":"#15A1B1","rejectButtonDisplay":true,"showPurposesToggles":true,"showTitle":false,"slideDown":false,"usesThirdParties":false }};
  </script>
  <script type="text/javascript" src="//cs.iubenda.com/sync/2779444.js"></script>
  <script type="text/javascript" src="//cdn.iubenda.com/cs/iubenda_cs.js" charset="UTF-8" async></script>`;

  const gTagManagerScript = `
  (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
  new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
  j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
  'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
  })(window,document,'script','dataLayer','GTM-PFWQSTQZ');`

  const router = useRouter();

  var pageId = structure.page.id ?? '';

  return (
    <div>
      <Head>
        <title>{structure.page.title}</title>
        <meta name="description" content={structure.page.description} />
        { router.asPath.includes('page/page/') ? 
          (<meta name="robots" content="noindex" />) :
          (<link rel="canonical" href={process.env.BASE_URL + `${router.asPath.replace('page/', '')}`} />)
        }
      <script dangerouslySetInnerHTML={{ __html: gTagManagerScript }}></script>
      </Head>
      <div dangerouslySetInnerHTML={{ __html: iubendaImplementation }}></div>
      <noscript>
        <iframe src="https://www.googletagmanager.com/ns.html?id=GTM-PFWQSTQZ" height="0" width="0" style={{display:'none', visibility: 'hidden'}}></iframe>
      </noscript>
      <main
        className={`${structure.page.template} cmsPage`}
        id={pageId}
      >
        {structure.rows.map(function (row, i) {
            return <CmsRow row={row} page_id={structure.page.id} key={i} />;
        })}
      </main>
      <CmsFooter structure={structure} />
    </div>
  );
}

function applyAosToElement(selector, effect) {
  if (document.querySelector(selector)) {
    document.querySelector(selector).setAttribute("data-aos", effect);
  }
}

function applyAosToElementByIndex(selector, index, effect) {
  if (document.querySelectorAll(selector)[index]) {
    document.querySelectorAll(selector)[index].setAttribute("data-aos", effect);
  }
}

export default CmsPage;
